import { LinkType } from '../components/layout/header';

const useLinks = (): LinkType[] => {
  return [
    {
      label: `Notre histoire`,
      path: `/notre-histoire`,
    },
    {
      label: `Trouver un point de vente`,
      path: `/points-de-vente`,
    },
  ];
};

export default useLinks;
