import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';

import mq from '../styles/mq';

import { ThemeType } from '../styles/theme';

const useButtonStyle = () => {
  const { color } = useTheme<ThemeType>();

  return css`
    appearance: none;
    border: 0;
    border-radius: 50px;
    background-color: ${color.accent};
    color: ${color.white};
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 800;
    padding: 10px 50px;
    text-decoration: none;
    transition: opacity 0.3s;

    &:disabled {
      opacity: 0.5;
    }

    ${mq(`lg`)} {
      font-size: 20px;
    }
  `;
};

export default useButtonStyle;
