import React, { useState } from 'react';

import Header from './header';
import Mobile from './mobile';
import Footer from './footer';

interface LayoutProps {
  children: React.ReactNode;
  footer?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children, footer = false }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <section>
      <Header />
      <Mobile open={open} setOpen={setOpen} />
      <main role="main">{children}</main>
      {footer && <Footer />}
    </section>
  );
};

export default Layout;
