import React from 'react';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import { Link } from 'gatsby';

import mq from '../../styles/mq';

import { ThemeType } from '../../styles/theme';

const Footer: React.FC = () => {
  const { color } = useTheme<ThemeType>();

  return (
    <footer
      css={css`
        background-color: ${color.primary};
        padding: 50px 0;
        text-align: center;
      `}
    >
      <ul
        css={css`
          margin-bottom: 20px;

          ${mq(`md`)} {
            display: flex;
            justify-content: center;
          }

          li {
            margin-right: 20px;

            &:last-of-type {
              margin-right: 0;
            }

            a {
              color: white;
              text-decoration: none;
            }
          }
        `}
      >
        <li>
          <Link to="/mentions-legales">Mentions légales</Link>
        </li>
        <li>
          <Link to="/conditions-vente">Conditions générales de vente</Link>
        </li>
        <li>
          <Link to="/donnees-personnelles">Traitement des données</Link>
        </li>
      </ul>
      <p
        css={css`
          color: ${color.white};
        `}
      >
        &copy; Copyright {new Date().getFullYear()} 3D Beer
      </p>
    </footer>
  );
};

export default Footer;
