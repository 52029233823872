import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import { Link } from 'gatsby';
import { animated as a, useSpring } from 'react-spring';

import mq from '../../styles/mq';
import useLinks from '../../hooks/useLinks';

import Logo from '../../svg/logo.svg';

import { ThemeType } from '../../styles/theme';

interface MobileProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const Mobile: React.FC<MobileProps> = ({ open, setOpen }) => {
  const { color } = useTheme<ThemeType>();
  const [active, setActive] = useState<boolean>(false);
  const sticky = useSpring({
    background: active ? `hsla(0, 0%, 100%, 100%)` : `hsla(0, 0%, 100%, 0%)`,
    boxShadow: active
      ? `0 5px 10px hsla(0, 0%, 0%, .1)`
      : `0 0 0 hsla(0, 0%, 0%, 0%)`,
  });
  const links = useLinks();

  const onScroll = () =>
    window.pageYOffset > 100 ? setActive(true) : setActive(false);

  useEffect(() => {
    document.addEventListener(`scroll`, onScroll);

    return () => document.removeEventListener(`scroll`, onScroll);
  });

  const translate = useSpring({
    transform: open
      ? `translate3d(0px, 0%, 0px)`
      : `translate3d(0px, -200%, 0px)`,
  });

  const renderLinks = () => (
    <ul
      css={css`
        height: 100%;
      `}
    >
      {links.map((link, key) => (
        <li
          key={key}
          css={css`
            margin-bottom: 20px;

            a {
              color: ${color.white};
              font-size: 20px;
              text-decoration: none;
            }
          `}
        >
          <Link to={link.path}>{link.label}</Link>
        </li>
      ))}
    </ul>
  );

  return (
    <>
      <a.div
        style={sticky}
        css={css`
          top: 0;
          right: 0;
          left: 0;
          position: fixed;
          width: 100%;
          z-index: 9998;
          padding: 20px 0;

          ${mq(`md`)} {
            display: none;
          }
        `}
      >
        <figure
          css={css`
            text-align: center;

            svg {
              width: auto;
              height: 50px;
            }
          `}
        >
          <Link to="/">
            <Logo />
          </Link>
        </figure>
      </a.div>
      <div
        onClick={() => setOpen(!open)}
        css={css`
          position: fixed;
          right: 20px;
          bottom: 20px;
          width: 70px;
          height: 70px;
          border-radius: 50%;
          background-color: ${color.primary};
          z-index: 10000;
          display: flex;
          justify-content: center;
          align-items: center;
          color: ${color.white};
          font-weight: 400;
          border: 2px solid ${color.white};
          cursor: pointer;

          ${mq(`md`)} {
            display: none;
          }
        `}
      >
        {open ? `fermer` : `menu`}
      </div>
      <a.nav
        style={translate}
        css={css`
          background-color: ${color.primary};
          height: 100vh;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          position: fixed;
          z-index: 9999;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <div>
          <figure
            css={css`
              margin-bottom: 100px;

              svg {
                width: 100px;
                height: auto;
              }
            `}
          >
            <Link to="/">
              <Logo />
            </Link>
          </figure>
          {renderLinks()}
        </div>
      </a.nav>
    </>
  );
};

export default Mobile;
