import React, { useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { Link } from 'gatsby';
import { animated as a, useSpring } from 'react-spring';
import Container from '@material-ui/core/Container';

import mq from '../../styles/mq';
import useButtonStyle from '../../hooks/useButtonStyle';
import useLinks from '../../hooks/useLinks';

import Logo from '../../svg/logo.svg';

export type LinkType = {
  label: string;
  path: string;
};

const Header: React.FC = () => {
  const buttonStyle = useButtonStyle();
  const [active, setActive] = useState<boolean>(false);
  const sticky = useSpring({
    background: active ? `hsla(0, 0%, 100%, 100%)` : `hsla(0, 0%, 100%, 0%)`,
    boxShadow: active
      ? `0 5px 10px hsla(0, 0%, 0%, .1)`
      : `0 0 0 hsla(0, 0%, 0%, 0%)`,
  });

  const links = useLinks();

  const onScroll = () =>
    window.pageYOffset > 100 ? setActive(true) : setActive(false);

  useEffect(() => {
    document.addEventListener(`scroll`, onScroll);

    return () => document.removeEventListener(`scroll`, onScroll);
  });

  const renderLinks = useCallback(
    () => (
      <ul
        css={css`
          display: flex;
          justify-content: flex-end;
          align-items: center;
        `}
      >
        {links.map((link, key) => (
          <li
            key={key}
            css={css`
              margin-right: 50px;

              &:last-of-type {
                margin-right: 0;

                a {
                  ${buttonStyle};
                  padding: 10px 20px;
                }
              }
            `}
          >
            <Link
              to={link.path}
              title={link.label}
              css={css`
                color: #3e3130;
                font-size: 20px;
                font-weight: 900;
                text-decoration: none;

                &:hover {
                  text-decoration: none;
                }
              `}
            >
              {link.label}
            </Link>
          </li>
        ))}
      </ul>
    ),
    [links]
  );

  return (
    <a.header
      style={sticky}
      css={css`
        position: absolute;
        width: 100%;
        top: 0;
        right: 0;
        left: 0;
        padding: 30px 0;
        z-index: 1001;
        display: none;

        ${mq(`md`)} {
          display: block;
        }
      `}
    >
      <Container>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <figure
            css={css`
              svg {
                width: 100px;
                height: auto;
              }
            `}
          >
            <Link to="/" title="Page d'accueil">
              <Logo />
            </Link>
          </figure>
          <div
            css={css`
              display: flex;
              margin-left: auto;
            `}
          >
            {renderLinks()}
          </div>
        </div>
      </Container>
    </a.header>
  );
};

export default Header;
